.container{
    width: 100vw;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap :7rem;
    padding: 0 2rem;
}

body{
	font-family: 'Poppins', sans-serif;
	overflow: hidden;
	background-image: url("../../assets/wa-background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}
