.react-trello-board section {
  max-height: calc(100vh - 95px);
}

.react-trello-board .react-trello-card  {
   width: 99%;
}

.react-trello-board .react-trello-card div div {
  margin-bottom: -3px;
}

.react-trello-board .react-trello-card div div p {
  margin: -5px 0 4px 0;
}

.react-trello-board .react-trello-card header {
  height: 20px;
  margin-top: -5px;  
}

.react-trello-board .react-trello-card header span:nth-child(1) {
  font-size: 13px;
}

.react-trello-board .react-trello-card header span:nth-child(2) {
  padding-right: 4px;
}

.tituloTag {
  text-shadow:#444444 2px -2px 5px, #444444 -2px 2px 5px, #444444 2px 2px 5px, #444444 -2px -2px 5px;
  color: #fff;
}
